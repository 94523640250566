import MuiLink from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

const CssLink = styled(MuiLink)(({ theme, style, source }) => {
  return { ...theme?.link, ...theme?.link?.[source], ...style }
})

const Link = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <>
      <CssLink {...props} theme={theme}>
        {children}
      </CssLink>
    </>
  )
}
export default Link
