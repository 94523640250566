import { deleteVideo, getNotFlaggedVideos } from '@appt-digital/frontend-api-lib-huunoo'
import Paper from '@mui/material/Paper'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { OrganisationContext } from '../../context/OrganisationContext'
import { getAppUsers } from '../../handlers/requests/users/appUsersHandler'
import Button from '../../ui-library/components/Button'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import Dropdown from '../../ui-library/components/Dropdown'
import Link from '../../ui-library/components/Link'
import TabHeader from '../../ui-library/components/TabHeader'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import VideoButton from '../../ui-library/components/VideoButton'

const Videos = () => {
  const { theme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const { currentOrg } = useContext(OrganisationContext)
  const [videos, setVideos] = useState([])
  const [sort, setSort] = useState({
    label: 'Created Ascending',
    value: 'createdAt,ASC',
  })
  const [user, setUser] = useState()
  const [userData, setUserData] = useState([])
  const [page, setPage] = useState(1)
  const [limit] = useState(9)
  const [max, setMax] = useState(1)
  const getVids = async () => {
    const res = await getNotFlaggedVideos(page, limit, sort?.value, user?.value)
    setVideos(res?.data.data)
  }
  const getUsers = async () => {
    const users = await getAppUsers('')
    setUserData(users?.data)
  }

  useEffect(() => {
    getVids()
  }, [currentOrg, sort, user])

  useEffect(() => {
    getUsers()
  }, [currentOrg])

  return (
    <DashboardContainer>
      <TabHeader title={'All Videos'}>
        <Paper
          style={{
            ...theme?.dashboard?.container,
            ...theme?.dashboard?.container?.[''],
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              width: '80%',
              alignItems: 'center',
            }}
          >
            <Dropdown
              title={'Sort By'}
              placeholder={'Sort By'}
              setSelected={(val) => {
                setSort(val)
              }}
              selected={sort}
              items={[
                { label: 'Created Ascending', value: 'createdAt,ASC' },
                { label: 'Created Descending', value: 'createdAt,DESC' },
                { label: 'Views, Ascending', value: 'views,ASC' },
                { label: 'Views, Descending', value: 'views,DESC' },
              ]}
            />
            <Dropdown
              title={'User'}
              placeholder={'User'}
              setSelected={(val) => setUser(val)}
              selected={user}
              items={[
                { label: 'All', value: '' },
                ...(userData?.length > 0
                  ? userData?.map((item) => ({
                      label: item?.firstname + ' ' + item?.lastname,
                      value: item?.id,
                    }))
                  : []),
              ]}
            />
            <Link
              style={{ width: '100%', marginTop: 17 }}
              onClick={() => navigate('/dashboard/archive')}
            >
              View Archive
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {videos?.map((item) => {
              return (
                <VideoButton
                  key={uuid()}
                  item={item}
                  image={item?.thumbnailURL}
                  url={item?.playbackURL}
                  id={item?.id}
                  getVids={getVids}
                  deleteVideo={deleteVideo}
                />
              )
            })}
          </div>
          {max < 1 ? (
            <></>
          ) : (
            <Button
              style={{ margin: 'auto', marginTop: 20 }}
              onClick={async () => {
                const res = await getNotFlaggedVideos(
                  page + 1,
                  limit,
                  sort?.value
                )
                setPage(page + 1)
                setMax(res?.data.data.length + 1 - limit)
                setVideos((prev) => [...prev, ...res?.data.data])
              }}
            >
              Load More
            </Button>
          )}
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default Videos
