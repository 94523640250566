import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import placeholder from '../../assets/logo-placeholder.png'
import { ThemeContext } from './ThemeContext'

const Image = styled('img')(({ theme, style, source }) => {
  return { ...theme?.logo, ...theme?.logo?.[source], ...style }
})

const Logo = (props) => {
  const { image, alt } = props
  const { theme } = useContext(ThemeContext)
  return (
    <Image
      {...props}
      theme={theme}
      src={image || theme?.logo?.image || placeholder}
      alt={alt}
    />
  )
}

export default Logo
