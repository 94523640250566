import * as Yup from 'yup'
import uiText from '../resources/uiText'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
})

const activateSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
  terms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
})

const resetSchema = Yup.object().shape({
  email: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
})
const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
})

const addUserSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
})

export {
  resetSchema,
  activateSchema,
  loginSchema,
  addUserSchema,
  passwordSchema,
}
