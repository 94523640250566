import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../../config/validation'
import { addAppUserHandler } from '../../../../handlers/requests/users/appUsersHandler'
import { AlertContext } from '../../../../context/AlertContext'
import TextField from '../../../../ui-library/components/TextField'
import Button from '../../../../ui-library/components/Button'
import Link from '../../../../ui-library/components/Link'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import DateSelect from '../../../../ui-library/components/DateSelect'

function AddAppUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [selectedOrgs] = useState([])
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      emailAddress: '',
      homePhone: '',
      mobile: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      postcode: '',
      dob: '5/16/2000',
    },
    validationSchema: addUserSchema,
    onSubmit: () => handleAdd(),
  })

  const handleAdd = async () => {
    await addAppUserHandler({ values, selectedOrgs }, setAlert, setLoading)
    navigate('/dashboard/app')
  }
  return (
    <>
      <DashboardContainer>
        <TabHeader title={state?.title}>
          <Paper style={theme?.dashboard?.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/app')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h1'}>User Details</Typography>

                <form onSubmit={handleSubmit}>
                  <Typography source={'h3'}>Personal Details</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                    }}
                  >
                    <TextField
                      type="firstname"
                      name="firstname"
                      title="Firstname"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'firstname'}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="lastname"
                      name="lastname"
                      title="Lastname"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'lastname'}
                      errors={errors}
                      touched={touched}
                    />
                    <DateSelect
                      type="dob"
                      name="dob"
                      title="Date of Birth"
                      placeholder="Date of Birth"
                      date={values.dob}
                      touched={touched}
                      onBlur={handleBlur}
                      errors={errors}
                      setDate={(val) => setFieldValue('dob', val)}
                    />
                  </div>
                  <Typography source={'h3'}>Location</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                    }}
                  >
                    <TextField
                      type="addressLineOne"
                      name="addressLineOne"
                      title="Address Line One"
                      placeholder="Address Line One"
                      value={values.addressLineOne}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`addressLineOne`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="addressLineTwo"
                      name="addressLineTwo"
                      title="Address Line Two"
                      placeholder="Address Line Two"
                      value={values.addressLineTwo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`addressLineTwo`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="city"
                      name="city"
                      title="City"
                      placeholder="City"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`city`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="postcode"
                      name="postcode"
                      title="Postcode"
                      placeholder="Postcode"
                      value={values.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`postcode`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <Typography source={'h3'}>Contact Details</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                    }}
                  >
                    <TextField
                      type="emailAddress"
                      name="emailAddress"
                      title="Email"
                      placeholder="Email"
                      value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`emailAddress`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="homePhone"
                      name="homePhone"
                      title="Home Phone"
                      placeholder="Home Phone"
                      value={values.homePhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`homePhone`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="mobile"
                      name="mobile"
                      title="Mobile"
                      placeholder="Mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`mobile`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <Button type="submit">Save</Button>
                </form>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default AddAppUser
