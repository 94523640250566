import { getArchivedVideos } from '@appt-digital/frontend-api-lib-huunoo'
import Paper from '@mui/material/Paper'
import React, { useContext, useEffect, useState } from 'react'
import { OrganisationContext } from '../../context/OrganisationContext'
import { getAppUsers } from '../../handlers/requests/users/appUsersHandler'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import TabHeader from '../../ui-library/components/TabHeader'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import Typography from '../../ui-library/components/Typography'
import VideoButton from '../../ui-library/components/VideoButton'

const ArchivedVideos = () => {
  const { theme } = useContext(ThemeContext)
  const { currentOrg } = useContext(OrganisationContext)
  const [videos, setVideos] = useState([])
  const [sort] = useState({
    label: 'Created Ascending',
    value: 'createdAt,ASC',
  })
  const [user] = useState()
  const [, setUserData] = useState([])
  const [page] = useState(1)
  const [limit] = useState(10)
  const getVids = async () => {
    const res = await getArchivedVideos(
      page,
      limit,
      user?.value || '',
      sort?.value
    )
    setVideos(res?.data)
  }
  const getUsers = async () => {
    const users = await getAppUsers('')
    setUserData(users?.data)
  }

  useEffect(() => {
    getUsers()
  }, [currentOrg])
  useEffect(() => {
    getVids()
  }, [user, sort, currentOrg])

  return (
    <DashboardContainer>
      <TabHeader title={'All Videos'}>
        <Paper
          style={{
            ...theme?.dashboard?.container,
            ...theme?.dashboard?.container?.[''],
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              width: '60%',
              alignItems: 'center',
            }}
          >
            {/* <Dropdown */}
            {/*  title={'Sort By'} */}
            {/*  placeholder={'Sort By'} */}
            {/*  setSelected={(val) => { */}
            {/*    setSort(val) */}
            {/*  }} */}
            {/*  selected={sort} */}
            {/*  items={[ */}
            {/*    { label: 'Created Ascending', value: 'createdAt,ASC' }, */}
            {/*    { label: 'Created Descending', value: 'createdAt,DESC' }, */}
            {/*    { label: 'Views, Ascending', value: 'views,ASC' }, */}
            {/*    { label: 'Views, Descending', value: 'views,DESC' }, */}
            {/*  ]} */}
            {/* /> */}
            {/* <Dropdown */}
            {/*  title={'User'} */}
            {/*  placeholder={'User'} */}
            {/*  setSelected={(val) => setUser(val)} */}
            {/*  selected={user} */}
            {/*  items={[ */}
            {/*    { label: 'All', value: '' }, */}
            {/*    ...(userData?.length > 0 */}
            {/*      ? userData?.map((item) => ({ */}
            {/*          label: item?.firstname + ' ' + item?.lastname, */}
            {/*          value: item?.id, */}
            {/*        })) */}
            {/*      : []), */}
            {/*  ]} */}
            {/* /> */}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {videos?.length === 0 ? (
              <Typography
                source={'h1'}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '50vh',
                }}
              >
                No videos found
              </Typography>
            ) : (
              videos?.map((item, index) => {
                return (
                  <VideoButton
                    archived
                    item={item}
                    image={item?.thumbnailURL}
                    url={item?.playbackURL}
                    id={item?.id}
                    key={item?.id}
                    getVids={getVids}
                  />
                )
              })
            )}
          </div>
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default ArchivedVideos
