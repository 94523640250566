import React, { useContext, useEffect } from 'react'
import Typography from '../ui-library/components/Typography'
import { UserContext } from '../context/UserContext'
import { useNavigate } from 'react-router-dom'
import LoadingAnim from '../ui-library/components/loadingAnim/LoadingAnim'

const NotFound = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (user === null) navigate('/login')
    else {
      navigate('/dashboard')
    }
  }, [])
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography source="h1">404 Not Found</Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '25%',
        }}
      >
        <Typography source="breadcrumb">Redirecting</Typography>
      </div>
      <LoadingAnim />
    </div>
  )
}

export default NotFound
