import React, { useContext } from 'react'
import TextField from './TextField'
import SearchIcon from '@mui/icons-material/Search'
import { ThemeContext } from './ThemeContext'
import StyledIcon from './StyledIcon'
const SearchField = (props) => {
  const { source, style, iconPosition } = props
  const { theme } = useContext(ThemeContext)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TextField
        {...props}
        style={{
          ...theme?.searchBox,
          ...theme?.searchBox?.[source],
          ...style,
        }}
        icon={
          <StyledIcon
            style={{
              ...theme?.searchBox?.icon,
              ...theme?.searchBox?.[source]?.icon,
              ...style?.icon,
            }}
            Icon={SearchIcon}
          />
        }
        iconPosition={iconPosition || 'start'}
      />
    </div>
  )
}

export default SearchField
