import React from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
const NavButton = (props) => {
  const { destination, params, children, ...rest } = props
  const navigate = useNavigate()
  const handleNavigation = () => {
    navigate(destination, { replace: true, state: { ...params } })
  }
  return (
    <Button {...rest} onClick={handleNavigation}>
      {children}
    </Button>
  )
}

export default NavButton
