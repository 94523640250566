import {
  deleteUser,
  getRoleByTitle,
  getUsers,
  inviteUser,
  resetPassword,
  updateUser,
} from '@appt-digital/frontend-api-lib-huunoo'
import uiText from '../../../resources/uiText'

const editAppUser = async (id, data, setAlert) => {
  const values = {
    firstname: data?.values?.firstname || '',
    lastname: data?.values?.lastname || '',
    jobTitle: data?.values?.jobTitle || '',
    departmentKey: data?.values?.departmentKey || '',
    title: data?.values?.title,
    ...(data?.values?.emailAddress && { username: data?.values?.emailAddress }),
    ...(data?.values?.emailAddress && { email: data?.values?.emailAddress }),
    ...(data?.values?.bio && { bio: data?.values?.bio }),
    ...(data?.values?.servicesOffered &&
      typeof data?.values?.servicesOffered === 'string' && {
        servicesOffered: data?.values?.servicesOffered.split(','),
      }),
    ...(data?.values?.homePhone && {
      homeNumber: data?.values?.homePhone,
    }),
    ...(data?.values?.addressLineTwo && {
      addressLineTwo: data?.values?.addressLineTwo,
    }),
  }
  const req = await updateUser(id, values)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error updating user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User updated',
    action: false,
  })
}

const addAdminUserHandler = async (data, setAlert) => {
  const addUserData = {
    username: data.values.emailAddress,
    email: data.values.emailAddress,
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    title: data.values.title || data.title,
    departmentKey: data.values.departmentKey,
    jobTitle: data.values.jobTitle,
    type: 'admin',
  }
  const roles = await getRoleByTitle('Admin')
  const req = await inviteUser(addUserData, roles[0]?.id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addAppUserHandler = async (data, setAlert) => {
  const values = {
    username: data.values.emailAddress,
    email: data.values.emailAddress,
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    departmentKey: data.values.departmentKey,
    jobTitle: data.values.jobTitle,
    type: 'trader',
  }

  try {
    const roles = await getRoleByTitle('User')
    const req = await inviteUser(values, roles[0]?.id)
    if (req.name === 'AxiosError') {
      return setAlert({
        open: true,
        severity: 'error',
        message: 'Error adding user',
        action: false,
      })
    }
    setAlert({
      open: true,
      severity: 'success',
      message: 'User added.',
      action: false,
    })
  } catch (err) {}
}

const getAdminUsers = async (search = 'jon') => {
  const params =
    '{ "$or": [{"email": {"$contL": "' +
    search +
    '"}}, {"firstname": {"$contL": "' +
    search +
    '"}}, {"lastname": {"$contL": "' +
    search +
    '"}}], "type": "admin"}'
  return await getUsers({ s: params })
}

const getAppUsers = async (search = 'jon', sort) => {
  var params =
    '{ "$or": [{"email": {"$contL": "' +
    search +
    '"}}, {"firstname": {"$contL": "' +
    search +
    '"}}, {"lastname": {"$contL": "' +
    search +
    '"}}], "type": {"$ne": "admin"}}'
  return await getUsers({ s: params })
}

const deleteAppUser = async (id, setAlert) => {
  const req = await deleteUser(id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User deleted.',
    action: false,
  })
}
const resetAppUser = async (email, setAlert) => {
  const req = await resetPassword(email)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: uiText.errors.reset,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Password reset request sent',
  })
}
export {
  editAppUser,
  addAdminUserHandler,
  addAppUserHandler,
  getAppUsers,
  getAdminUsers,
  deleteAppUser,
  resetAppUser,
}
