import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import color from '../styles/color'
const ToolTip = () => {
  return (
    <MuiTooltip style={{ color: color.primary }} title="TEXT NEEDED">
      <IconButton icon={HelpIcon} />
    </MuiTooltip>
  )
}

export default ToolTip
