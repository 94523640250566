import MUILoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import { ThemeContext } from './ThemeContext'
import React, { useContext } from 'react'
import StyledIcon from './StyledIcon'

const CssLoadingButton = styled(MUILoadingButton)(
  ({ style, theme, source }) => {
    return {
      ...theme?.loadingButton,
      ...theme?.loadingButton?.[source],
      ...style,
    }
  }
)

const LoadingButton = (props) => {
  const { children, icon, icontype, source, style } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssLoadingButton
      {...props}
      {...{
        [icontype]: (
          <StyledIcon
            style={{
              ...theme?.button?.icon,
              ...theme?.button?.[source]?.icon,
              ...style?.icon,
            }}
            Icon={icon}
          />
        ),
      }}
      theme={theme}
      style={{
        fonFamily: 'co-text, serif',
      }}
    >
      {children}
    </CssLoadingButton>
  )
}

MUILoadingButton.defaultProps = {
  variant: 'contained',
  loadingPosition: 'end',
}
export default LoadingButton
