const formData = {
  shared: {
    path: 'Home >',
  },
  tabs: [
    {
      key: 1,
      title: 'All Videos',
      route: '/dashboard/videos',
    },
    {
      key: 2,
      title: 'Flagged Videos',
      route: '/dashboard/flagged',
    },
    {
      key: 2,
      title: 'App Users',
      route: '/dashboard/app',
    },
    {
      key: 3,
      title: 'System Users',
      route: '/dashboard/system',
    },
    {
      key: 4,
      title: 'Subscriptions',
      route: '/dashboard/subscriptions',
    },
  ],
}
const getFormData = (key, value) =>
  formData.tabs.find((t) => t.key === key)[value]

export { formData, getFormData }
