const color = {
  primary: '#F56821',
  primary2: '#FFFFFF',
  primary3: '#4B4D52',
  success: '',
  error: '#ff0000',
  warning: '#ffb600',
  placeholder: '#000000',
  shadow: 'rgba(75,77,82,0.5)',
  background: '#CBFAFF',

  // Hover
  primaryHover: '#dc5d1d',
  primary2Hover: '#00B4CBFF',
  primary3Hover: '#39393f',
  errorHover: '#be0000',
  warningHover: '#be0000',

  // wireframe
  grey: '#4f4e4e',
  white: '#FFFFFF',
  black: '#000000',
  hover: '#707070',
}

export default color
