import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'
import LockIcon from '@mui/icons-material/Lock'
import ToolTip from './ToolTip'

const Counter = (props) => {
  const { theme } = useContext(ThemeContext)
  const {
    style,
    source,
    text = 'default',
    Icon = LockIcon,
    count,
    toolTip = '',
  } = props
  const styles = {
    container: {
      ...theme?.counter?.container,
      ...theme?.counter?.[source]?.container,
      ...style?.container,
    },
    icon: {
      ...theme?.counter?.icon,
      ...theme?.counter?.[source]?.icon,
      ...style?.icon,
    },
    count: {
      ...theme?.counter?.count,
      ...theme?.counter?.[source]?.count,
      ...style?.count,
    },
    text: {
      ...theme?.counter?.text,
      ...theme?.counter?.[source]?.text,
      ...style?.text,
    },
  }

  return (
    <div style={styles.container}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Icon style={styles.icon} />
        <Typography source={'h5'} style={styles.count}>
          {count}
        </Typography>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Typography source={'body'} style={styles.text}>
          {text}
        </Typography>
        {toolTip ? <ToolTip /> : <></>}
      </div>
    </div>
  )
}

export default Counter
