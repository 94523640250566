import { deleteGDPR, getGDPR } from '@appt-digital/frontend-api-lib-huunoo'

const getGDPRData = async (id, username) => {
  const res = await getGDPR({}, id)
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${username}.xlsx`) // or any other extension
  document.body.appendChild(link)
  link.click()
}

const deleteGDPRData = async (id) => {
  return await deleteGDPR({}, id)
}

export { getGDPRData, deleteGDPRData }
