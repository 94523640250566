import { authenticateToken, login } from '@appt-digital/frontend-api-lib-huunoo'
import localStorageService from '../../../utils/localStorageService'

const loginHandler = async (
  values,
  navigate,
  setAlert,
  setLoading,
  setUser,
  setOrganisations
) => {
  setLoading(true)
  const res = await login(values.username, values.password)
  if (res.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error logging into portal user.',
      action: false,
    })
    setLoading(false)
    return
  }

  if (res.data.type !== 'admin') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error only admins have acccess to the dashboard.',
      action: false,
    })
    setLoading(false)
    return

  } else if (res.data.twoFAToken) {
    navigate('/authenticate', { state: { twoFAToken: res.data.twoFAToken } })
  } else {
    setUser(res.data.user)
    setOrganisations(res.data?.user?.organisations)
    localStorageService.set('user', res?.data?.user)
    navigate('/dashboard')
  }

  setLoading(false)
}

const authenticate2fa = async (
  code,
  token,
  navigate,
  setAlert,
  setLoading,
  setUser,
  setOrganisations
) => {
  const res = await authenticateToken({}, { code }, token)
  if (!res?.data) {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Invalid 2FA, please try again or return to login.',
      action: false,
    })
  }

  setUser(res.data.user)
  setOrganisations(res.data?.user?.organisations)
  localStorageService.set('user', res?.data?.user)
  navigate('/dashboard')
  setLoading(false)
}

export { loginHandler, authenticate2fa }
