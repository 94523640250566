import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import style from '../../style/style'
import Sidebar from '../../navigation/sidebar/Sidebar'
import TimeoutLogicDialog from './dialogs/TimeoutLogicDialog'

import Background from './Background'
import LoadingAnim from './loadingAnim/LoadingAnim'

export default function DashboardContainer(props) {
  const { children } = props
  const [loading] = useState(false)

  return (
    <Background>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300,
          }}
        >
          <LoadingAnim width={300} height={300} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <Grid container spacing={[3]} sx={style.dashboard}>
            <>
              <Grid item md={3} lg={3} xl={3}>
                <Sidebar />
              </Grid>
              <TimeoutLogicDialog />

              <Grid item md={9} lg={9} xl={8} sm={12} xs={12}>
                {children}
              </Grid>
            </>
          </Grid>
        </div>
      )}
    </Background>
  )
}
