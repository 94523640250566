import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

const StyledIcon = (props) => {
  const { Icon, source, style, ...rest } = props
  const { theme } = useContext(ThemeContext)
  return (
    <>
      {Icon ? (
        <Icon
          {...rest}
          style={{
            ...theme?.styledIcon,
            ...theme?.styledIcon?.[source],
            ...style,
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default StyledIcon
