import color from './color'

const defaultTheme = {
  logo: {
    // DEFAULT START
    width: '205px',
    height: 'auto',
    marginBottom: 20,
    // DEFAULT END
  },
  background: {
    // DEFAULT START
    backgroundColor: color.grey,
    backgroundSize: '100% 100%',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    boxShadow: 'none',
    // DEFAULT END
    auth: {
      backgroundColor: `white`,
    },
  },
  input: {},
  password: {
    // DEFAULT START
    icon: {
      container: {
        backgroundColor: color.white,
      },
      color: color.black,
    },
    strengthMeter: {
      container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
      bar: {
        margin: 5,
        width: 50,
        height: 4,
        borderRadius: 10,
      },
    },
    // DEFAULT END
  },
  checkBox: {
    // DEFAULT START
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
    // DEFAULT END
  },
  radioButton: {
    // DEFAULT START
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
    // DEFAULT END
  },
  alert: {},
  textField: {
    // DEFAULT START
    backgroundColor: color.white,
    borderRadius: 10,
    height: 45,
    width: '100%',
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 5,
    },
    border: `1px solid ${color.black}`,
    '& input': {
      '&::placeholder': {
        color: color.placeholder,
      },
    },
    '& fieldset': {
      border: '1px',
      borderColor: color.white,
    },
    '&:hover fieldset': {
      border: `1px solid ${color.black}`,

      transition: '.2s ease-in-out',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${color.black}`,
    },
    // DEFAULT END
    // CUSTOM START
    // CUSTOM END
  },
  dropDown: {
    // DEFAULT START
    container: {
      width: '100%',
    },
    select: {
      control: (provided, state) => ({
        ...provided,
        border: state.isFocused
          ? `2px solid ${color.black}`
          : `1px solid ${color.black}`,
        boxShadow: 'none',
        padding: 10,
        borderRadius: 10,
        margin: state.isFocused ? -1 : 0, // counteracts the 2px border so it doesn't move the input
        '&:hover': {
          border: `2px solid ${color.grey}`,
          margin: -1, // counteracts the 2px border so it doesn't move the input
        },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? color.white : color.white,
        color: state.isSelected ? color.black : color.black,
        padding: 10,
        '&:hover': {
          backgroundColor: color.grey,
          color: color.white,
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '4px',
        boxShadow: 'none',
        '&:before': {
          content: '""',
          display: 'block',
          height: '8px',
        },
      }),
      multiValue: (base) => ({
        ...base,
        marginRight: 5,
        border: `1px solid ${color.black}`,
        borderRadius: 15,
        padding: '2px 5px',
      }),
    },
    // DEFAULT END
  },
  sidebar: {
    header: {
      backgroundColor: '#929292FF',
      height: 75,
      marginTop: -10,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 15,
      padding: 5,
      justifyContent: 'center',
    },
    background: {
      backgroundColor: color.white,
      padding: '10px 0',
      minHeight: '650px',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      marginBottom: 10,
    },
  },
  dashboard: {
    header: {},
    container: {
      backgroundColor: '#FFF',
      borderRadius: 0,
      borderRadiusBottomRight: '10px',
      borderRadiusBottomLeft: '10px',
      minHeight: '650px',
      padding: 15,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      marginBottom: 10,
    },
    background: {},
  },
  dateSelect: {
    container: { display: 'flex', flexDirection: 'column', width: '100%' },
    '& .MuiOutlinedInput-root': {
      backgroundColor: color.white,
      borderRadius: 10,
      height: 45,
      marginBottom: 15,
      border: `1px solid ${color.black}`,
      '& input': {
        '&::placeholder': {
          color: color.placeholder,
        },
      },
      '& fieldset': {
        border: '1px',
        borderColor: color.white,
      },
      '&:hover fieldset': {
        border: `1px solid ${color.black}`,
        transition: '.2s ease-in-out',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${color.black}`,
      },
    },
  },
  searchBox: {
    borderRadius: 15,
    height: 30,
    width: '90%',
  },
  button: {
    // DEFAULT START
    height: 45,
    width: 200,
    borderRadius: 10,
    marginTop: 10,
    textTransform: 'none',
    backgroundColor: color.black,
    '&:hover': {
      backgroundColor: color.grey,
    },
    icon: {
      width: 20,
      height: 20,
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    selected: {
      backgroundColor: color.grey,
      width: '100%',
    },
    sidebar: {
      width: '100%',
    },
    // CUSTOM END
  },
  loadingButton: {
    // DEFAULT START
    height: 45,
    width: '100%',
    margin: 5,
    borderRadius: 10,
    textTransform: 'none',
    justifyContent: 'space-between',
    backgroundColor: color.black,
    '&:hover': {
      backgroundColor: color.grey,
    },
    icon: {
      width: 20,
      height: 20,
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    // CUSTOM END
  },
  iconButton: {
    // DEFAULT START
    backgroundColor: color.grey,
    ':hover': { backgroundColor: color.grey },
    icon: {
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    tableIcon: {
      backgroundColor: 'transparent',
      ':hover': { backgroundColor: color.grey },
      icon: {
        color: color.black,
      },
    },
    // CUSTOM END
  },
  link: {
    color: color.black,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: color.grey,
    },
  },
  tabHeader: {
    backgroundColor: '#929292FF',
    height: 85,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  dataTable: {
    shadowBorder: {
      tableContainer: {},
      table: {
        borderCollapse: 'separate',
        borderSpacing: '10px 20px',
      },
      tableHead: {},
      tableRow: {
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
      },
      tableCell: {
        borderBottom: 'none',
        padding: 5,
        paddingLeft: 10,
      },
      tableBody: {},
    },
  },
  breadcrumbs: {
    textDecoration: 'none',
    marginTop: 5,
  },
  dropZone: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 150,
      width: '100%',
      backgroundColor: color.grey,
    },
    img: {
      height: 150,
      width: 350,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
    },
    noImage: {
      color: 'white',
    },
    icon: { color: color.black, width: 35, height: 35 },
    title: { color: color.white },
    label: { color: color.black },
    filename: {},
    error: {},
  },
  dynamicList: {},
  notificationBadge: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 90,
    width: 25,
    height: 25,
    // textAlign: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: -10,
  },
  counter: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      border: '1px',
      borderColor: color.white,
      borderRadius: 15,
      boxShadow: '0px 0px 6px #00000029',
      marginBottom: 15,
      width: '100%',
    },
    icon: {
      width: 40,
      height: 40,
      padding: 5,
    },
    count: {
      fontSize: 35,
      fontFamily: 'Helvetica Neue',
    },
    text: {
      textTransform: 'uppercase',
      fontSize: 13,
      paddingLeft: 10,
      marginRight: 30,
    },
  },
  styledIcon: {
    pointerEvents: 'none',
    color: color.black,
  },
  typography: {
    fontFamily: 'Noto-Regular',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    textTransform: 'none',
    h1: {
      fontSize: '2em',
      spacing: 0,
      fontWeight: 'bolder',
    },
    h2: {
      fontSize: '1.5em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h3: {
      fontSize: '1.17em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h4: {
      fontSize: '1em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h5: {
      fontSize: '0.83em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h6: {
      fontSize: '0.67em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    body: {
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
    },
    body2: {
      fontSize: 18,
      fontWeight: 'bold',
      spacing: 0,
    },
    subtitle: {
      color: color.black,
      fontSize: 25,
      fontWeight: 'bold',
      spacing: 0,
      textAlign: 'center',
    },
    subtitle2: {
      color: color.black,
      fontSize: 15,
      fontWeight: 'bold',
    },
    inputLabel: {
      color: color.black,
      fontSize: 14,
    },
    paragraph: {
      fontSize: 15,
    },
    caption: {
      textAlign: 'center',
      fontSize: '18px',
      color: 'blue',
    },
    caption2: {
      textAlign: 'center',
      paddingTop: '30px',
      fontSize: '16px',
    },
    link: {
      color: color.black,
      fontSize: 13,
      fontWeight: 0,
      spacing: 0,
    },
    title: {
      color: color.black,
      fontSize: 20,
      fontWeight: 0,
      spacing: 0,
    },
    checkbox: {
      color: color.black,
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
      userSelect: 'none',
    },
    breadcrumbs: {},
  },
  chartWrapper: {
    bordered: {
      container: {
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
        padding: 10,
        marginTop: '20px',
      },
    },
  },
  accordion: {},
  pieChart: {},
  circleChart: {},
  lineChart: {},
}
export default defaultTheme
