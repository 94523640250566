import React from 'react'
import Card from '@mui/material/Card'
import style from '../../style/style'
import Typography from './Typography'

const ErrorCard = (props) => {
  const { type, errors, touched } = props

  return (
    <Card sx={errors?.[type] && touched?.[type] ? style.error : {}}>
      {errors?.[type] && touched?.[type] ? (
        <Typography source={'errorText'}>{errors?.[type]}</Typography>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default ErrorCard
