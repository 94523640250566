import { createPortalUser } from '@appt-digital/frontend-api-lib-huunoo'

const registerHandler = async (values, navigate, setAlert) => {
  const req = await createPortalUser(values.username, values.password)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error registering portal user.',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Account created',
    action: false,
  })
  navigate('/login')
}

const handleTermsClick = () => {
  window.open('https://www.google.co.uk', '_blank')
}

export { registerHandler, handleTermsClick }
