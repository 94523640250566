import React, { useContext } from 'react'
import MuiPaper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { ThemeContext } from './ThemeContext'

const Paper = styled(MuiPaper)(({ theme, style, source }) => {
  return {
    ...theme?.background,
    ...theme?.background?.[source],
    ...style,
  }
})

const Background = (props) => {
  const { theme } = useContext(ThemeContext)
  return <Paper {...props} theme={theme} />
}

export default Background
