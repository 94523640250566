import React, { useContext, useState } from 'react'
import Button from './components/Button'
import LockIcon from '@mui/icons-material/Lock'
import LoadingButton from './components/LoadingButton'
import IconButton from './components/IconButton'
import TextField from './components/TextField'
import DynamicList from './components/DynamicList'
import Logo from './components/Logo'
import CheckboxList from './components/CheckboxList'
import Dropdown from './components/Dropdown'
import DateSelect from './components/DateSelect'
import Link from './components/Link'
import DropZone from './components/DropZone'
import PasswordField from './components/PasswordField'
import SearchField from './components/SearchField'
import Typography from './components/Typography'
import BreadCrumbs from './components/BreadCrumbs'
import Counter from './components/Counter'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { AlertContext } from '../context/AlertContext'
const KitchenSink = () => {
  const [loading, setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          width: '50vw',
        }}
      >
        <div>
          <Typography source={'h1'}>Wireframe</Typography>
          <Typography source={'h2'}>Wireframe</Typography>
          <Typography source={'h3'}>Wireframe</Typography>
          <Typography source={'h4'}>Wireframe</Typography>
          <Typography source={'h5'}>Wireframe</Typography>
          <Typography source={'h6'}>Wireframe</Typography>
        </div>
        <div>
          <Button
            icontype={'endIcon'}
            icon={LockIcon}
            onClick={() =>
              setAlert({
                open: true,
                severity: 'success',
                message: 'Test Message',
                action: false,
              })
            }
          >
            Wireframe
          </Button>
          <LoadingButton
            Icon={LockIcon}
            icontype={'startIcon'}
            loading={loading}
            onClick={() => setLoading(true)}
          >
            Loading
          </LoadingButton>
          <IconButton icon={LockIcon} />
        </div>
        <TextField />
        <PasswordField />
        <SearchField icon placeholder={'Search'} />
        <DynamicList />
        <Logo />
        <CheckboxList items={[1, 2, 3]} />
        <Dropdown items={[0, 1, 2, 3, 4]} Icon={ArrowDropDownIcon} />
        <DateSelect />
        <Link>Link</Link>
        <BreadCrumbs />
        <DropZone />
        <Counter text={'Placeholder'} Icon={LockIcon} count={3} />
      </div>
    </div>
  )
}

export default KitchenSink
