import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import { FaFileUpload } from 'react-icons/fa'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'
import { FileUploader } from 'react-drag-drop-files'
import StyledIcon from './StyledIcon'

function DropZone(props) {
  const { source, style } = props
  const { theme } = useContext(ThemeContext)
  const styles = { ...theme.dropZone, ...theme.dropZone?.[source], ...style }

  const { setFile, logo } = props
  const [fileSelected, setFileSelected] = useState(logo)
  const [error, setError] = useState(null)

  const checkFile = (file) => {
    const extensions = ['jpeg', 'png', 'gif']
    const type = file.type.split('/')[1]
    if (extensions.includes(type)) {
      setFileSelected(URL.createObjectURL(file))
      setError(null)
      createFile(file)
    } else {
      setFileSelected(null)
      setError('only jpg, png and gif')
    }
  }

  const createFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setFile(formData)
  }
  const dragDropFileTypes = ['JPG', 'PNG', 'GIF', 'PDF']
  return (
    <div style={{ width: 350 }}>
      <FileUploader
        handleChange={checkFile}
        name="file"
        types={dragDropFileTypes}
        classes={'drag'}
      >
        <label htmlFor="file_upload" style={styles.container}>
          <img
            src={logo ?? fileSelected}
            style={{
              ...theme.dropZone?.img,
              ...theme.dropZone?.[source]?.img,
              ...style,
            }}
          />
          <StyledIcon
            style={{
              ...theme.dropZone?.icon,
              ...theme?.dropZone?.[source]?.icon,
              ...style?.icon,
            }}
            Icon={FaFileUpload}
          />
          <Typography>Upload Logo</Typography>
          <input
            id="file_upload"
            type="file"
            onChange={(ev) => {
              checkFile(ev.target.files[0])
            }}
            style={{ display: 'none' }}
          />
          {fileSelected && (
            <Box style={styles.filename}>
              {fileSelected ? fileSelected?.name : null}
            </Box>
          )}
          {error && <Box style={styles.error}>{error}</Box>}
        </label>
      </FileUploader>
    </div>
  )
}

export default DropZone
