import React, { useContext, useEffect, useState } from 'react'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import TabHeader from '../../ui-library/components/TabHeader'
import Paper from '@mui/material/Paper'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import Dropdown from '../../ui-library/components/Dropdown'
import { useLocation } from 'react-router-dom'
import { getKPIS } from '@appt-digital/frontend-api-lib-huunoo'
import Typography from '../../ui-library/components/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DateSelect from '../../ui-library/components/DateSelect'
import Button from '../../ui-library/components/Button'
import Counter from '../../ui-library/components/Counter'
import color from '../../ui-library/styles/color'
import { FaClock, FaMoneyBillAlt, FaUserPlus } from 'react-icons/fa'

const Subscriptions = () => {
  const today = new Date()
  const startOfWeek = new Date(today)
  const dayOfWeek = today.getDay()
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // adjust when day is Sunday
  startOfWeek.setDate(diff)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const [dateType, setDateType] = useState({ label: 'Week', value: 'WEEK' })
  const [dateFrom, setDateFrom] = useState(startOfWeek)
  const [dateTo, setDateTo] = useState(new Date())
  const [revenue, setRevenue] = useState(0)
  const [minsWatched, setMinsWatched] = useState(0)
  const [newSubs, setNewSubs] = useState(0)
  const [topTraders, setTopTraders] = useState([])
  const [submit, setSubmit] = useState(false)
  const [selectAll] = useState([
    {
      displayName: 'Select all the Organisations I have access to',
      enabled: false,
    },
  ])

  const getAllStats = async () => {
    const res = await getKPIS(dateFrom, dateTo)
    setRevenue(`£${(res?.data?.subs[0]?.rev / 100).toFixed(2)}`)
    setNewSubs(res?.data?.subs[0]?.count)
    setMinsWatched(res?.data?.minutesWatched[0]?.total_minutes_watched)
    setTopTraders(res?.data?.topTraders)
  }

  const handleDateSelected = () => {
    const today = new Date()
    // WEEK
    const startOfWeek = new Date(today)
    const dayOfWeek = today.getDay()
    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // adjust when day is Sunday
    startOfWeek.setDate(diff)
    const endOfWeek = new Date(startOfWeek)
    endOfWeek.setDate(endOfWeek.getDate() + 6)
    // WEEK
    // MONTH
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    // MONTH
    // YEAR
    const startOfYear = new Date(today.getFullYear(), 0, 1)
    const endOfYear = new Date(today.getFullYear(), 11, 31)
    // YEAR
    switch (dateType.value) {
      case 'WEEK':
        setDateFrom(startOfWeek)
        setDateTo(endOfWeek)

        break
      case 'MONTH':
        setDateFrom(startOfMonth)
        setDateTo(endOfMonth)

        break
      case 'YEAR':
        setDateFrom(startOfYear)
        setDateTo(endOfYear)

        break
      // default:
    }
  }
  useEffect(() => {
    handleDateSelected()
  }, [dateType, selectAll])
  useEffect(() => {
    getAllStats()
  }, [submit])
  useEffect(() => {
    getAllStats()
  }, [])
  return (
    <DashboardContainer>
      <TabHeader title={state?.title}>
        <Paper
          style={{
            ...theme?.dashboard?.container,
            ...theme?.dashboard?.container?.[''],
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              width: '80%',
            }}
          >
            <Dropdown
              type="dateType"
              id={'dateType'}
              name={'dateType'}
              title={'Time Period'}
              setSelected={(selected) => {
                setDateType(selected)
              }}
              selected={dateType}
              items={[
                { label: 'Week', value: 'WEEK' },
                { label: 'Month', value: 'MONTH' },
                { label: 'Year', value: 'YEAR' },
              ]}
              Icon={ArrowDropDownIcon}
              placeholder={'Select period...'}
            />
            <DateSelect date={dateFrom} setDate={setDateFrom} title={'From'} />
            <DateSelect date={dateTo} setDate={setDateTo} title={'To'} />
            <Button
              style={{ marginTop: 20 }}
              onClick={() => {
                setSubmit(!submit)
              }}
            >
              Submit
            </Button>
          </div>
          <div
            style={{
              margin: 20,
              borderBottom: '1px solid grey',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Counter
              text={'Total Viewing Minutes'}
              Icon={FaClock}
              count={minsWatched}
            />
            <Counter
              text={'New Subscriptions'}
              Icon={FaUserPlus}
              count={newSubs}
            />
            <Counter
              text={'Estimated New Revenue'}
              Icon={FaMoneyBillAlt}
              count={revenue}
            />
          </div>
          <div
            style={{
              margin: 20,
              borderBottom: '1px solid grey',
            }}
          />
          <Typography source={'h1'}>Top Traders (by views)</Typography>
          {topTraders?.map((item, index) => {
            return (
              <div
                key={`toptrader-`+index}
                style={{ display: 'flex', flexDirection: 'row', gap: 20 }}
              >
                <div style={{ color: color.primary }}>{index + 1}</div>
                <div>
                  <b>{item.email}</b> - Minutes Watched -{' '}
                  <b>{item.total_minutes_watched}</b>
                </div>
              </div>
            )
          })}
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default Subscriptions
