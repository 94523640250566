import React, { useState, useMemo } from 'react'
import { ThemeProvider } from './ui-library/components/ThemeContext'
import StackContext from './context/StackContext'
import AppChild from './AppChild'
import { UserProvider } from './context/UserContext'
import { AlertProvider } from './context/AlertContext'
import { OrganisationProvider } from './context/OrganisationContext'
import './assets/fonts/Noto_Sans/NotoSans-Black.ttf'
import './App.css'
import Background from './ui-library/components/Background'

function App() {
  const [stack, setStack] = useState([])
  const stackProvider = useMemo(() => ({ stack, setStack }), [stack, setStack])

  return (
    <ThemeProvider>
      <StackContext.Provider value={stackProvider}>
        <UserProvider>
          <AlertProvider>
            <OrganisationProvider>
              <Background source={'auth'}>
                <AppChild />
              </Background>
            </OrganisationProvider>
          </AlertProvider>
        </UserProvider>
      </StackContext.Provider>
    </ThemeProvider>
  )
}

export default App
