import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '../../../../ui-library/components/Button'
import {
  getAdminUsers,
  resetAppUser,
} from '../../../../handlers/requests/users/appUsersHandler'
import { OrganisationContext } from '../../../../context/OrganisationContext'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../ui-library/components/DataTable'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import LoadingAnim from '../../../../ui-library/components/loadingAnim/LoadingAnim'
import useDebounce from '../../../../hooks/useDebounce'
import moment from 'moment'
import uuid from 'react-uuid'
import Typography from '../../../../ui-library/components/Typography'
import IconButton from '../../../../ui-library/components/IconButton'
import { FaDatabase, FaEdit, FaLink, FaUser, FaUserPlus } from 'react-icons/fa'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useNavigate } from 'react-router-dom'
import { AlertContext } from '../../../../context/AlertContext'
import ConfirmationModal from '../../../../ui-library/components/dialogs/ConfirmationModal'

function UsersList(props) {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const { setAlert } = useContext(AlertContext)
  const debouncedSearch = useDebounce(search, 1000)
  const navigate = useNavigate()
  const [confirmReset, setConfirmReset] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)

  useEffect(() => {
    const appUsers = async () => {
      setLoading(true)
      if (debouncedSearch) {
        const users = await getAdminUsers(search)
        setUserData(users?.data)
        setLoading(false)
      } else {
        const users = await getAdminUsers('')
        setUserData(users?.data)
        setLoading(false)
      }
    }
    appUsers()
  }, [currentOrg, debouncedSearch])

  return (
    <DashboardContainer>
      <TabHeader
        title={'System Users'}
        showSearch
        searchValue={search}
        onChangeSearch={(e) => setSearch(e.target.value)}
      >
        <Paper
          style={{
            ...theme?.dashboard?.container,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Button
              source={'altButton'}
              icon={FaUserPlus}
              icontype={'endIcon'}
              onClick={() => {
                navigate('/dashboard/system/add', {
                  state: { title: 'Add System User' },
                })
              }}
            >
              Add User
            </Button>
            <Button
              icon={FaDatabase}
              icontype={'endIcon'}
              onClick={() => {
                navigate('/dashboard/system/gdpr')
              }}
            >
              GDPR Centre
            </Button>
          </div>
          <>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  <LoadingAnim />
                </div>
              ) : userData?.length > 0 ? (
                <TableContainer {...props} theme={theme}>
                  <Table {...props} theme={theme}>
                    <TableHead {...props} theme={theme}>
                      {['Name', 'Last Login', 'Action']?.map(
                        (header, index) => {
                          return (
                            <TableCell {...props} key={index} theme={theme}>
                              <Typography
                                style={
                                  header === 'Action'
                                    ? {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginRight: 20,
                                      }
                                    : {}
                                }
                              >
                                {header}
                              </Typography>
                            </TableCell>
                          )
                        }
                      )}
                    </TableHead>
                    {userData?.map((user, index) => {
                      const firstname =
                        user?.firstname !== null ? user?.firstname : ''
                      const lastname =
                        user?.lastname !== null ? user?.lastname : ''
                      const lastLoggedIn = moment(user?.lastLoggedIn).fromNow(
                        true
                      )
                      const notInvalidLastLogin =
                        lastLoggedIn === 'Invalid date' ? 'N/A' : lastLoggedIn
                      return (
                        <TableRow theme={theme} {...props} key={uuid()}>
                          <TableCell theme={theme} {...props} align="left">
                            <Typography>{`${firstname} ${lastname}`}</Typography>
                          </TableCell>

                          <TableCell theme={theme} {...props}>
                            <Typography>{notInvalidLastLogin}</Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <IconButton
                              source={'tableIcon'}
                              style={{ float: 'right', color: 'red' }}
                              icon={FaLink}
                              onClick={() => {
                                setSelectedUser(user)
                                setConfirmReset(true)
                              }}
                            />
                            <IconButton
                              source={'tableIcon'}
                              style={{ float: 'right' }}
                              icon={FaEdit}
                              onClick={() => {
                                navigate('/dashboard/system/edit', {
                                  state: { user },
                                })
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  source={'h1'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  No Data
                </Typography>
              )}
            </Stack>
            <ConfirmationModal
              onAccept={async () => {
                await resetAppUser(selectedUser?.email, setAlert)
                setConfirmReset(false)
              }}
              isOpen={confirmReset}
              onRequestClose={() => setConfirmReset(false)}
              type={'Reset'}
              text={'Are you sure you wish to reset this users password?'}
              icon={FaUser}
            />
          </>
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default UsersList
