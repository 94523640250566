import React from 'react'
import Dialog from '@mui/material/Dialog'
import StyledIcon from '../StyledIcon'
import { ImCross } from 'react-icons/im'
import color from '../../styles/color'

function VideoModal({ isOpen, onRequestClose, url }) {
  return (
    <div>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <div
          style={{
            position: 'absolute',
          }}
          onClick={(e) => {
            e.stopPropagation()
            onRequestClose()
          }}
        >
          <StyledIcon
            Icon={ImCross}
            style={{
              color: color.primary,
              margin: 10,
              width: 40,
              height: 40,
            }}
          />
        </div>
        <iframe
          onClick={(e) => {
            e.stopPropagation()
          }}
          src={url + '?autoplay=1'}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; autoplay;"
          allowfullscreen="true"
          style={{ width: 420, height: 850 }}
        />
      </Dialog>
    </div>
  )
}

export default VideoModal
