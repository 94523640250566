import React, { useState } from 'react'
import StyledIcon from './StyledIcon'
// eslint-disable-next-line import/no-duplicates
import {
  deleteVideo,
  restoreVideo,
} from '@appt-digital/frontend-api-lib-huunoo'
import { AiOutlineEye } from 'react-icons/ai'
import { FaBolt, FaPlay, FaUndo } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import Typography from './Typography'
import ConfirmationModal from './dialogs/ConfirmationModal'
import VideoModal from './dialogs/VideoModal'

const VideoButton = (props) => {
  const { image, url, id, getVids, item, archived, getArchivedVideos } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmRestore, setConfirmRestore] = useState(false)
  return (
    <>
      <div
        style={{
          margin: 10,
          width: 250,
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={() => setModalOpen(true)}
      >
        <img style={{ width: '100%', height: 350 }} src={image} alt={''} />
        <StyledIcon
          Icon={FaPlay}
          style={{
            position: 'absolute',
            height: 50,
            width: 50,
            color: 'white',
            marginLeft: -140,
            marginTop: 150,
            opacity: 0.7,
          }}
        />
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: 230,
            height: 30,
            marginTop: -33,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: 120,
              alignItems: 'center',
            }}
          >
            <StyledIcon
              Icon={AiOutlineEye}
              style={{
                color: '#0FFFFF',
                height: 30,
                width: 30,
              }}
            />
            <Typography style={{ color: 'white' }}>{item?.views}</Typography>
            <StyledIcon
              Icon={FaBolt}
              style={{ color: 'white', height: 23, width: 23 }}
            />
            <Typography style={{ color: 'white' }}>
              {item?.favouriteCount}
            </Typography>
          </div>
          {archived ? (
            <div
              style={{ alignItems: 'center' }}
              onClick={async (e) => {
                e.stopPropagation()
                setConfirmRestore(true)
                await getArchivedVideos()
                setModalOpen(false)
              }}
            >
              <StyledIcon
                Icon={FaUndo}
                style={{ color: 'white', height: 20, width: 20 }}
              />
            </div>
          ) : (
            <div
              onClick={async (e) => {
                e.stopPropagation()
                setConfirmDelete(true)
                setModalOpen(false)
              }}
            >
              <StyledIcon
                Icon={RiDeleteBin6Fill}
                style={{ color: 'white', height: 25, width: 25 }}
              />
            </div>
          )}
        </div>
      </div>
      <VideoModal
        url={url}
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      />
      <ConfirmationModal
        onAccept={async () => {
          await deleteVideo(id)
          setConfirmDelete(false)
          await getVids()
        }}
        isOpen={confirmDelete}
        onRequestClose={() => setConfirmDelete(false)}
        type={'Delete'}
        text={'Are you sure you wish to delete this video?'}
        icon={MdDeleteForever}
      />
      <ConfirmationModal
        onAccept={async () => {
          await restoreVideo(id)
          setConfirmRestore(false)
          await getVids()
        }}
        isOpen={confirmRestore}
        onRequestClose={() => setConfirmRestore(false)}
        type={'Restore'}
        text={'Are you sure you wish to restore this video?'}
        icon={FaUndo}
      />
    </>
  )
}

export default VideoButton
