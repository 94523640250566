import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../screens/auth/Login'
import Register from '../../screens/auth/Register'
import Reset from '../../screens/auth/Reset'
import NotFound from '../../screens/NotFound'
import Success from '../../screens/Success'
import Failure from '../../screens/Failure'
import Password from '../../screens/auth/Password'
import KitchenSink from '../../ui-library/KitchenSink'
import Auth2FA from '../../screens/auth/Auth2FA'
import Gdpr from '../../screens/dashboard/users/system/GDPR'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import UsersList from '../../screens/dashboard/users/system/UsersList'
import EditUser from '../../screens/dashboard/users/system/EditUser'
import AddUser from '../../screens/dashboard/users/system/AddUser'
import Videos from '../../screens/videos/Videos'
import AddAppUser from '../../screens/dashboard/users/app/AddUser'
import EditAppUser from '../../screens/dashboard/users/app/EditUser'
import AppUsersList from '../../screens/dashboard/users/app/UsersList'
import GdprAppUser from '../../screens/dashboard/users/app/GDPR'
import FlaggedVideos from '../../screens/videos/FlaggedVideos'
import Subscriptions from '../../screens/subscriptions/Subscriptions'
import ArchivedVideos from '../../screens/videos/ArchivedVideos'

function Router() {
  return (
    <Routes>
      {/*  AUTH ROUTES */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/authenticate" element={<Auth2FA />} />
      <Route path="/reset-password" element={<Password />} />
      {/* DASHBOARD ROUTES */}
      <Route path="/dashboard" element={<DashboardContainer />} />
      <Route path="/dashboard/system" element={<UsersList />} />
      <Route path="/dashboard/system/add" element={<AddUser />} />
      <Route path="/dashboard/system/edit" element={<EditUser />} />
      <Route path="/dashboard/app" element={<AppUsersList />} />
      <Route path="/dashboard/app/add" element={<AddAppUser />} />
      <Route path="/dashboard/app/edit" element={<EditAppUser />} />
      <Route path="/dashboard/APP/gdpr" element={<GdprAppUser />} />
      <Route path="/dashboard/system/gdpr" element={<Gdpr />} />
      {/*  Videos */}
      <Route path="/dashboard/videos" element={<Videos />} />
      <Route path="/dashboard/archive" element={<ArchivedVideos />} />
      <Route path="/dashboard/flagged" element={<FlaggedVideos />} />
      {/*  Subscriptions */}
      <Route path="/dashboard/subscriptions" element={<Subscriptions />} />
      {/* MISC */}
      <Route path="/sink" element={<KitchenSink />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/success" element={<Success />} />
      <Route path="/failure" element={<Failure />} />
    </Routes>
  )
}

export default Router
