import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from '../Button'
import style from '../../../style/style'
import StyledIcon from '../StyledIcon'
import color from '../../styles/color'

function ConfirmationModal({
  isOpen,
  onAccept,
  onRequestClose,
  text,
  type,
  icon,
}) {
  return (
    <div>
      <Dialog open={isOpen} sx={style.timeoutStyle} onClose={onRequestClose}>
        <DialogTitle>
          <StyledIcon
            Icon={icon}
            style={{ color: color.primary, marginBottom: -3, paddingRight: 5 }}
          />
          {type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ color: 'black' }}>{text}</Typography>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button source={'secondary'} onClick={onAccept}>
            Yes
          </Button>
          <Button source={'altButton'} onClick={onRequestClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationModal
