import {
  acceptVideo,
  declineVideo,
  deleteVideo,
  getFlaggedVideos,
} from '@appt-digital/frontend-api-lib-huunoo'
import Paper from '@mui/material/Paper'
import React, { useContext, useEffect, useState } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import uuid from 'react-uuid'
import { OrganisationContext } from '../../context/OrganisationContext'
import { getAppUsers } from '../../handlers/requests/users/appUsersHandler'
import Button from '../../ui-library/components/Button'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import TabHeader from '../../ui-library/components/TabHeader'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import Typography from '../../ui-library/components/Typography'
import VideoButton from '../../ui-library/components/VideoButton'
import ConfirmationModal from '../../ui-library/components/dialogs/ConfirmationModal'
const FlaggedVideos = () => {
  const { theme } = useContext(ThemeContext)
  const [videos, setVideos] = useState([])
  const { currentOrg } = useContext(OrganisationContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [sort] = useState({
    label: 'Created Ascending',
    value: 'createdAt,ASC',
  })
  const [user] = useState()
  const [, setUserData] = useState([])

  const [page] = useState(0)
  const [limit] = useState(1000)

  const getvideos = async () => {
    const res = await getFlaggedVideos(
      page,
      limit,
      user?.value || '',
      sort?.value
    )
    setVideos(res?.data?.data)
  }

  useEffect(() => {
    getvideos()
  }, [sort, user, currentOrg])
  const getUsers = async () => {
    const users = await getAppUsers('')
    setUserData(users?.data)
  }
  useEffect(() => {
    getUsers()
  }, [currentOrg])
  return (
    <DashboardContainer>
      <TabHeader title={'Flagged Videos'}>
        <Paper
          style={{
            ...theme?.dashboard?.container,
            ...theme?.dashboard?.container?.[''],
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              width: '60%',
              alignItems: 'center',
            }}
          >
            {/* <Dropdown */}
            {/*  title={'Sort By'} */}
            {/*  placeholder={'Sort By'} */}
            {/*  setSelected={(val) => { */}
            {/*    setSort(val) */}
            {/*  }} */}
            {/*  selected={sort} */}
            {/*  items={[ */}
            {/*    { label: 'Created Ascending', value: 'createdAt,ASC' }, */}
            {/*    { label: 'Created Descending', value: 'createdAt,DESC' }, */}
            {/*    { label: 'Views, Ascending', value: 'views,ASC' }, */}
            {/*    { label: 'Views, Descending', value: 'views,DESC' }, */}
            {/*  ]} */}
            {/* /> */}
            {/* <Dropdown */}
            {/*  title={'User'} */}
            {/*  placeholder={'User'} */}
            {/*  setSelected={(val) => setUser(val)} */}
            {/*  selected={user} */}
            {/*  items={[ */}
            {/*    { label: 'All', value: '' }, */}
            {/*    ...(userData?.length > 0 */}
            {/*      ? userData?.map((item) => ({ */}
            {/*          label: item?.firstname + ' ' + item?.lastname, */}
            {/*          value: item?.id, */}
            {/*        })) */}
            {/*      : []), */}
            {/*  ]} */}
            {/* /> */}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {videos?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <VideoButton
                    key={uuid()}
                    item={item}
                    image={item?.thumbnailURL}
                    url={item?.playbackURL}
                    id={item?.id}
                    getVids={getvideos}
                    deleteVideo={deleteVideo}
                  />
                  <Typography>Reason: {item?.flags?.[0]?.reason}</Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 5,
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      source={'button'}
                      style={{ width: 110, fontSize: 13 }}
                      onClick={async () => {
                        await acceptVideo(item.id)
                        await getvideos()
                      }}
                    >
                      Keep Video
                    </Button>
                    <Button
                      source={'altButton'}
                      style={{ width: 130, fontSize: 13 }}
                      onClick={async () => {
                        setConfirmDelete(true)
                        setDeleteId(item.id)
                        await getvideos()
                      }}
                    >
                      Delete Video
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
          {/* {max < 1 ? ( */}
          {/*  <></> */}
          {/* ) : ( */}
          {/*  <Button */}
          {/*    style={{ margin: 'auto', marginTop: 20 }} */}
          {/*    onClick={async () => { */}
          {/*      setPage(page + 1) */}
          {/*      const res = await getFlaggedVideos( */}
          {/*        page, */}
          {/*        limit, */}
          {/*        user?.value || '', */}
          {/*        sort?.value */}
          {/*      ) */}
          {/*      setMax(res?.data.data.length + 1 - limit) */}
          {/*      setVideos((prev) => [...prev, ...res?.data.data]) */}
          {/*    }} */}
          {/*  > */}
          {/*    Load More */}
          {/*  </Button> */}
          {/* )} */}
        </Paper>
      </TabHeader>

      <ConfirmationModal
        onAccept={async () => {
          await declineVideo(deleteId)
          await deleteVideo(deleteId)
          getvideos()
          setConfirmDelete(false)
        }}
        isOpen={confirmDelete}
        onRequestClose={() => setConfirmDelete(false)}
        type={'Delete'}
        text={'Are you sure you wish to delete this video?'}
        icon={MdDeleteForever}
      />
    </DashboardContainer>
  )
}

export default FlaggedVideos
