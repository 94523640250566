import color from './color'
import logo from '../../assets/logo.png'

const typography = {
  typography: {
    fontFamily: 'co-headline',
    fontWeight: 400,
    fontStyle: 'normal',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    textTransform: 'none',
    color: color.primary3,
    h1: {
      fontSize: '2em',
      spacing: 0,
      fontWeight: 'bolder',
    },
    h2: {
      fontSize: '1.5em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h3: {
      fontSize: '1.17em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h4: {
      fontSize: '1em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h5: {
      fontSize: '0.83em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    h6: {
      fontSize: '0.67em',
      fontWeight: 'bolder',
      spacing: 0,
    },
    body: {
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
    },
    body2: {
      fontSize: 17,
      fontWeight: 0,
      spacing: 0,
    },
    subtitle: {
      fontSize: 25,
      spacing: 0,
      textAlign: 'center',
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    inputLabel: {
      fontSize: 14,
    },
    paragraph: {
      fontSize: 15,
    },
    caption: {
      textAlign: 'center',
      fontSize: '18px',
      color: 'blue',
    },
    caption2: {
      textAlign: 'center',
      paddingTop: '30px',
      fontSize: '16px',
    },
    title: {
      fontSize: 20,
      fontWeight: 0,
      spacing: 0,
    },
    button: {
      color: color.primary2,
      fontWeight: 'bolder',
    },
    checkbox: {
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
      userSelect: 'none',
    },
    breadcrumbs: {},
    authButton: {
      fontWeight: 'bolder',
    },
    authText: {
      fontWeight: 'bolder',
      color: color.primary2,
    },
    tabTitle: {
      fontSize: 25,
    },
  },
}

const theme = {
  ...typography,
  logo: {
    // DEFAULT START
    image: logo,
    width: '165px',
    height: 'auto',
    marginBottom: 90,
    // DEFAULT END
  },
  background: {
    // DEFAULT START
    backgroundColor: color.primary2,
    backgroundSize: '100% 100%',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    boxShadow: 'none',
    // DEFAULT END
  },
  input: {},
  password: {
    // DEFAULT START
    icon: {
      container: {
        backgroundColor: color.white,
      },
      color: color.primary3,
    },
    strengthMeter: {
      container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
      bar: {
        margin: 5,
        width: 50,
        height: 4,
        borderRadius: 10,
      },
    },
    // DEFAULT END
  },
  checkBox: {
    // DEFAULT START
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
    icon: {
      color: color.primary,
    },
    // DEFAULT END
  },
  radioButton: {
    // DEFAULT START
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
    // DEFAULT END
  },
  alert: {},
  textField: {
    // DEFAULT START
    backgroundColor: color.primary2,
    color: color.primary3,
    borderRadius: 10,
    height: 45,
    width: '100%',
    marginBottom: 7,
    fontFamily: 'co-text',
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 5,
    },

    border: `1px solid ${color.primary}`,
    '& input': {
      fontWeight: 'bolder',
      fontSize: '1rem',
      // marginBottom: 7,
      color: color.primary3,
      '&::placeholder': {},
    },
    '& fieldset': {
      border: '1px',
      borderColor: color.primary,
    },
    '&:hover fieldset': {
      border: `1px solid ${color.primary}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${color.primary}`,
    },
    // DEFAULT END
    // CUSTOM START
    // CUSTOM END
  },
  dropDown: {
    // DEFAULT START
    container: {
      width: '100%',
    },
    icon: {
      color: color.primary,
      width: 30,
      height: 30,
      marginTop: -3,
    },
    select: {
      valueContainer: (provided) => ({
        ...provided,
        transform: 'translate(0px, -2px)',
        // lineHeight: 2,
        fontFamily: 'co-text',
        color: color.primary3,
        fontWeight: 'bolder',
        fontSize: '1rem',
        '::placeholder': {
          color: color.primary,
        },
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        // transform: 'translateY(-5px)',
      }),
      control: (provided, state) => ({
        ...provided,
        border: state.isFocused
          ? `2px solid ${color.primary}`
          : `1px solid ${color.primary}`,
        boxShadow: 'none',
        padding: 10,
        borderRadius: 10,
        height: 45,
        '&:hover': {
          border: `2px solid ${color.primary}`,
        },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? color.white : color.white,
        color: state.isSelected ? color.primary : color.primary3,
        padding: 10,
        '&:hover': {
          backgroundColor: color.primary,
          color: color.primary2,
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        fontFamily: 'co-text',
        borderRadius: '4px',
        boxShadow: 'none',
        '&:before': {
          content: '""',
          display: 'block',
          height: '8px',
        },
        marginTop: -8,
      }),
      multiValue: (base) => ({
        ...base,
        fontFamily: 'co-text',
        marginRight: 5,
        border: `1px solid ${color.black}`,
        borderRadius: 15,
        padding: '2px 5px',
      }),
    },
    // DEFAULT END
  },
  sidebar: {
    header: {
      backgroundColor: color.primary2,
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,

      display: 'flex',
      alignItems: 'center',
      paddingLeft: 15,
      padding: 5,
      paddingTop: 40,
      justifyContent: 'center',
    },
    background: {
      backgroundColor: color.white,
      padding: '10px 0',
      minHeight: '750px',
      maxHeight: '100vh',
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      marginBottom: 10,
      boxShadow: `0 0 5px ${color.shadow}`,
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
  },
  dashboard: {
    header: {},
    container: {
      backgroundColor: '#FFF',
      borderRadius: 0,
      borderRadiusBottomRight: '10px',
      borderRadiusBottomLeft: '10px',
      minHeight: '650px',
      padding: 15,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      marginBottom: 20,
      boxShadow: `0 0 5px ${color.shadow}`,
    },
    background: {},
  },
  dateSelect: {
    container: { display: 'flex', flexDirection: 'column', width: '100%' },

    '& .MuiOutlinedInput-root': {
      backgroundColor: color.primary2,
      borderRadius: 10,
      height: 45,
      marginBottom: 15,
      '& .MuiDatePickerDay-daySelected': {
        backgroundColor: `${color.primary} !important`,
      },
      border: `1px solid ${color.primary}`,
      '& input': {
        '&::placeholder': {
          color: color.primary,
        },
      },
      '.MuiIconButton-root svg': {
        color: color.primary /* set the color here */,
      },
      '.MuiInputBase-input': {
        fontFamily: 'co-text',
        fontWeight: 'bold',
        color: color.primary3,
      },
      '& fieldset': {
        border: '1px',
        borderColor: color.white,
      },
      '&:hover fieldset': {
        border: `1px solid ${color.primary}`,
        transition: '.2s ease-in-out',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${color.primary}`,
      },
    },
  },
  searchBox: {
    borderRadius: 15,
    height: 30,
    width: '90%',
    paddingTop: 2,
    icon: {
      color: color.primary,
    },
  },
  button: {
    // DEFAULT START
    display: 'flex',
    justifyContent: 'space-between',
    height: 45,
    width: 250,
    borderRadius: 10,
    // paddingBottom: 15,
    backgroundColor: color.primary,
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '1rem',
    marginTop: 5,
    color: color.primary2,
    fontFamily: 'co-text',
    '&:hover': {
      backgroundColor: color.primaryHover,
      color: color.primary2,
    },
    icon: {
      width: 20,
      height: 20,
      marginTop: 'auto',
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    selected: {
      borderRadius: 20,
      height: 55,
      fontSize: '1.2rem',
      justifyContent: 'center',
      width: '100%',
      color: color.primary2,
      // paddingBottom: 30,
      // paddingTop: 20,
      marginTop: 0,
    },
    sidebar: {
      borderRadius: 20,
      height: 55,
      fontSize: '1.2rem',
      justifyContent: 'center',
      backgroundColor: color.primary2,
      width: '100%',
      color: color.primary3,
      boxShadow: `0 0 5px ${color.shadow}`,
      // paddingBottom: 30,
      // paddingTop: 20,
      marginTop: 0,
    },
    altButton: {
      backgroundColor: color.primary3,
      '&:hover': {
        backgroundColor: color.primary3Hover,
      },
    },
    // CUSTOM END
  },
  loadingButton: {
    // DEFAULT START
    display: 'flex',
    justifyContent: 'flex-start',
    height: 45,
    width: 200,
    borderRadius: 10,
    // paddingBottom: 15,
    backgroundColor: color.primary,
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '1rem',
    fontFamily: 'co-text',
    color: color.primary2,
    '&:hover': {
      backgroundColor: color.primaryHover,
      color: color.primary2,
    },
    icon: {
      width: 20,
      height: 20,
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    authButton: {
      width: '100%',
      marginBottom: 20,
    },
    // CUSTOM END
  },
  iconButton: {
    // DEFAULT START
    backgroundColor: color.grey,
    icon: {
      color: color.white,
    },
    // DEFAULT END
    // CUSTOM START
    tableIcon: {
      backgroundColor: 'transparent',
      ':hover': { backgroundColor: color.primary2 },
      icon: {
        color: color.primary,
      },
    },
    editButton: {
      width: 45,
      height: 45,
      marginTop: 8,
      ':hover': { backgroundColor: color.primary },
    },
    // CUSTOM END
  },
  link: {
    color: color.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 15,
    fontFamily: 'co-text',
    '&:hover': {
      color: color.grey,
    },
    breadcrumbs: {
      color: color.primary,
    },
  },
  tabHeader: {
    backgroundColor: color.primary2,
    height: 85,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    boxShadow: `0 0 5px ${color.shadow}`,
    marginBottom: 10,
  },
  dataTable: {
    tableContainer: {},
    table: {
      borderCollapse: 'separate',
      borderSpacing: '10px 20px',
    },
    tableHead: {},
    tableRow: {
      border: 'none',
      borderColor: color.white,
      borderRadius: 15,
    },
    tableCell: {
      borderBottom: 'none',
      padding: 5,
      paddingLeft: 10,
    },
    tableBody: {},

    shadowBorder: {
      tableContainer: {},
      table: {
        borderCollapse: 'separate',
        borderSpacing: '10px 20px',
      },
      tableHead: {},
      tableRow: {
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
      },
      tableCell: {
        borderBottom: 'none',
        padding: 5,
        paddingLeft: 10,
      },
      tableBody: {},
    },
  },
  breadcrumbs: {
    textDecoration: 'none',
    marginTop: 5,
  },
  dropZone: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 150,
      width: '100%',
      backgroundColor: color.grey,
    },
    img: {
      height: 150,
      width: 350,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
    },
    noImage: {
      color: 'white',
    },
    icon: { color: color.black, width: 35, height: 35 },
    title: { color: color.white },
    label: { color: color.black },
    filename: {},
    error: {},
  },
  dynamicList: {},
  notificationBadge: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 90,
    width: 25,
    height: 25,
    // textAlign: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: -10,
  },
  counter: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      border: '1px',
      borderColor: color.white,
      borderRadius: 15,
      // boxShadow: '0px 0px 6px #00000029',
      marginBottom: 15,
      width: '100%',
    },
    icon: {
      width: 70,
      height: 70,
      padding: 5,
      paddingLeft: 20,
      color: color.primary,
    },
    count: {
      fontSize: 35,
      fontFamily: 'Helvetica Neue',
    },
    text: {
      textTransform: 'uppercase',
      fontSize: 13,
      paddingLeft: 20,
    },
  },
  styledIcon: {
    pointerEvents: 'none',
    color: color.black,
  },

  chartWrapper: {
    bordered: {
      container: {
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
        padding: 10,
        marginTop: '20px',
      },
    },
  },
  accordion: {},
  pieChart: {},
  circleChart: {},
  lineChart: {},
}
export default theme
