import React, { useContext, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'

const CssSelect = styled(DatePicker)((props) => {
  const { theme, style, source } = props
  return {
    ...theme?.dateSelect,
    ...theme?.dateSelect?.[source],
    ...style,
  }
})

function DateSelect(props) {
  const { label, date, setDate, title } = props
  const [currentError, setCurrentError] = useState(null)
  const [errorDate, setErrorDate] = useState(false)
  const { theme } = useContext(ThemeContext)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={theme?.dateSelect?.container}>
        <Typography {...props} source={'inputLabel'}>
          {title}
        </Typography>
        <CssSelect
          label={label}
          inputFormat="dd/MM/yyyy"
          value={date}
          theme={theme}
          onChange={setDate}
          onError={(reason) => {
            if (reason) {
              setCurrentError('Please input a valid date')
              setErrorDate(true)
            } else {
              setCurrentError(null)
              setErrorDate(false)
            }
          }}
          renderInput={(props) => (
            <TextField
              error={errorDate}
              helperText={
                currentError ? (
                  <div
                    style={{
                      background: '#ff4438',
                      color: 'white',
                      marginBottom: '15px',
                      width: '105%',
                      padding: 7,
                      borderRadius: 5,
                      marginTop: -17,
                      marginLeft: -15,
                    }}
                  >
                    <Typography source={'errorText'}>{currentError}</Typography>
                  </div>
                ) : (
                  <></>
                )
              }
              {...props}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  )
}

export default DateSelect
