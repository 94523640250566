import React, { useContext } from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import Link from './Link'
import Typography from './Typography'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import toCapitalised from '../../utils/capitaliseString'
import { ThemeContext } from './ThemeContext'
const BreadCrumbs = (props) => {
  const { username, source, style, separator } = props
  const { theme } = useContext(ThemeContext)
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  return (
    <MuiBreadcrumbs
      style={{
        ...theme?.breadcrumbs,
        ...theme?.breadcrumbs?.[source],
        ...style,
      }}
      separator={separator}
      aria-label="Breadcrumb"
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const workaround =
          value === 'app' || value === 'system'
            ? toCapitalised(value) + ' Users'
            : toCapitalised(value)
        return last ? (
          <Typography source="breadcrumbs" key={to}>
            {value === 'gdpr' ? 'GDPR Centre' : workaround}
          </Typography>
        ) : (
          <Link
            source={'breadcrumbs'}
            color="inherit"
            component={RouterLink}
            to={to}
            key={to}
          >
            {value === 'gdpr' ? 'GDPR Centre' : workaround}
          </Link>
        )
      })}
      {username ? (
        <Typography source="breadcrumbs">{username}</Typography>
      ) : null}
    </MuiBreadcrumbs>
  )
}

export default BreadCrumbs
