import {
  getUserAuditTrail,
  getNotFlaggedVideos,
  isUserSubbed,
  deleteVideo,
} from '@appt-digital/frontend-api-lib-huunoo'
import Paper from '@mui/material/Paper'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { TbEdit, TbEditOff } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import ConfirmationModal from '../../../../ui-library/components/dialogs/ConfirmationModal'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import { addUserSchema } from '../../../../config/validation'
import { AlertContext } from '../../../../context/AlertContext'
import {
  deleteAppUser,
  editAppUser,
  resetAppUser,
} from '../../../../handlers/requests/users/appUsersHandler'
import Button from '../../../../ui-library/components/Button'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../ui-library/components/DataTable'
import DateSelect from '../../../../ui-library/components/DateSelect'
import IconButton from '../../../../ui-library/components/IconButton'
import Link from '../../../../ui-library/components/Link'
import TabHeader from '../../../../ui-library/components/TabHeader'
import TextField from '../../../../ui-library/components/TextField'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import Typography from '../../../../ui-library/components/Typography'
import toCapitalised from '../../../../utils/capitaliseString'
import VideoButton from '../../../../ui-library/components/VideoButton'

function EditAppUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const [edit, setEdit] = useState(false)
  const [audit, setAudit] = useState([])
  const [videos, setVideos] = useState([])
  const [max, setMax] = useState(1)
  const [page, setPage] = useState(1)
  const [limit] = useState(9)
  const [sort, setSort] = useState({
    label: 'Created Ascending',
    value: 'createdAt,ASC',
  })
  const [subbed, setSubbed] = useState(false)

  const navigate = useNavigate()
  const initialEdit = {
    firstname:
      state?.user?.firstname !== undefined && state?.user?.firstname !== null
        ? state?.user?.firstname
        : '',
    lastname:
      state?.user?.lastname !== undefined && state?.user?.lastname !== null
        ? state?.user?.lastname
        : '',
    emailAddress:
      state?.user?.email !== undefined && state?.user?.email !== null
        ? state?.user?.email
        : '',
    homePhone:
      state?.user?.homeNumber !== undefined && state?.user?.homeNumber !== null
        ? state?.user?.homeNumber
        : '',
    mobile:
      state?.user?.mobile !== undefined && state?.user?.mobile !== null
        ? state?.user?.mobile
        : '',
    addressLineOne:
      state?.user?.addressLineOne !== undefined &&
      state?.user?.addressLineOne !== null
        ? state?.user?.addressLineOne
        : '',
    addressLineTwo:
      state?.user?.addressLineTwo !== undefined &&
      state?.user?.addressLineTwo !== null
        ? state?.user?.addressLineTwo
        : '',

    city:
      state?.user?.city !== undefined && state?.user?.city !== null
        ? state?.user?.city
        : '',
    postcode:
      state?.user?.postcode !== undefined && state?.user?.postcode !== null
        ? state?.user?.postcode
        : '',
    dob:
      state?.user?.dob !== undefined && state?.user?.dob !== null
        ? state?.user?.dob
        : new Date(),
    bio:
      state?.user?.bio !== undefined && state?.user?.bio !== null
        ? state?.user?.bio
        : '',
    servicesOffered:
      state?.user?.servicesOffered !== undefined &&
      state?.user?.servicesOffered !== null
        ? state?.user?.servicesOffered
        : '',
  }

  const [selectedOrgs] = useState([])
  const [title] = useState(
    {
      label: state?.user?.title,
      value: state?.user?.title,
    } || { label: 'Mrs', value: 'mrs' }
  )
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialEdit,
    validationSchema: addUserSchema,
    onSubmit: async () => await handleEdit(),
  })
  const handleEdit = async () => {
    await editAppUser(
      state?.user?.id,
      { values, title, selectedOrgs },
      setAlert,
      setLoading
    )
    navigate('/dashboard/app')
  }
  const getVideos = async (page, limit) => {
    let res = await getNotFlaggedVideos(page, limit, null, state?.user?.id)
    setVideos(res?.data)
  }
  const getSubbed = async () => {
    setSubbed(`${await isUserSubbed(state?.user?.id)}`)
  }

  useEffect(() => {
    const getAuditTrail = async () => {
      const res = await getUserAuditTrail(state?.user?.id)
      setAudit(res?.data)
    }

    getSubbed()
    getVideos()
    getAuditTrail()
  }, [])
  return (
    <>
      <DashboardContainer>
        <TabHeader
          title={'Edit'}
          username={
            state?.user?.firstname !== null &&
            state?.user?.firstname !== undefined &&
            state?.user?.lastname !== null &&
            state?.user?.lastname !== undefined &&
            `${toCapitalised(state?.user?.firstname)} ${toCapitalised(
              state?.user?.lastname
            )}`
          }
        >
          <Paper style={theme?.dashboard?.container}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/app')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h1'}>User Details</Typography>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    marginBottom: 5,
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={() => {
                      setConfirmReset(true)
                    }}
                    icon={FaUser}
                    icontype={'endIcon'}
                    source={'altButton'}
                  >
                    Reset Password
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmDelete(true)
                    }}
                    icon={MdDeleteForever}
                    icontype={'endIcon'}
                  >
                    Delete User
                  </Button>
                  <IconButton
                    source={'editButton'}
                    onClick={() => setEdit(!edit)}
                    icon={edit ? TbEdit : TbEditOff}
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  {/* <Dropdown */}
                  {/*  type="title" */}
                  {/*  id={'title'} */}
                  {/*  name={'title'} */}
                  {/*  title={'Title'} */}
                  {/*  setSelected={setTitle} */}
                  {/*  onBlur={handleBlur} */}
                  {/*  selected={title} */}
                  {/*  items={[ */}
                  {/*    { label: 'Mr', value: 'Mr' }, */}
                  {/*    { label: 'Mrs', value: 'Mrs' }, */}
                  {/*    { label: 'Miss', value: 'Miss' }, */}
                  {/*    { label: 'Ms', value: 'Ms' }, */}
                  {/*    { label: 'Sir', value: 'Sir' }, */}
                  {/*  ]} */}
                  {/*  Icon={ArrowDropDownIcon} */}
                  {/*  errorType={'type'} */}
                  {/*  errors={errors} */}
                  {/*  touched={touched} */}
                  {/*  style={{ container: { width: '49.5%', marginBottom: 5 } }} */}
                  {/* /> */}

                  {edit ? (
                    <>
                      <Typography source={'h3'}>Personal Details</Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                        }}
                      >
                        <TextField
                          type="firstname"
                          name="firstname"
                          title="Firstname"
                          placeholder="First Name"
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={'firstname'}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="lastname"
                          name="lastname"
                          title="Lastname"
                          placeholder="Last Name"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={'lastname'}
                          errors={errors}
                          touched={touched}
                        />
                        <DateSelect
                          type="dob"
                          name="dob"
                          title="Date of Birth"
                          placeholder="Date of Birth"
                          date={values.dob}
                          setDate={(val) => setFieldValue('dob', val)}
                        />
                      </div>
                      <Typography source={'h3'}>Location</Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                        }}
                      >
                        <TextField
                          type="addressLineOne"
                          name="addressLineOne"
                          title="Address Line One"
                          placeholder="Address Line One"
                          value={values.addressLineOne}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`addressLineOne`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="addressLineTwo"
                          name="addressLineTwo"
                          title="Address Line Two"
                          placeholder="Address Line Two"
                          value={values.addressLineTwo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`addressLineTwo`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="city"
                          name="city"
                          title="City"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`city`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="postcode"
                          name="postcode"
                          title="Postcode"
                          placeholder="Postcode"
                          value={values.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`postcode`}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <Typography source={'h3'}>Contact Details</Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                        }}
                      >
                        <TextField
                          type="emailAddress"
                          name="emailAddress"
                          title="Email"
                          placeholder="Email"
                          value={values.emailAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`emailAddress`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="homePhone"
                          name="homePhone"
                          title="Home Phone"
                          placeholder="Home Phone"
                          value={values.homePhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`homePhone`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="mobile"
                          name="mobile"
                          title="Mobile"
                          placeholder="Mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`mobile`}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                        }}
                      >
                        <TextField
                          type="bio"
                          name="bio"
                          title="Bio"
                          placeholder="Bio"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`bio`}
                          errors={errors}
                          touched={touched}
                        />
                        <TextField
                          type="servicesOffered"
                          name="servicesOffered"
                          title="Services"
                          placeholder="Services"
                          value={values.servicesOffered}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errorType={`servicesOffered`}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <Typography
                        source={'h2'}
                        style={{
                          marginTop: 20,
                        }}
                      >
                        Personal Details
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Name:</Typography>
                        <Typography source={'body2'}>
                          {state?.user?.firstname} {state?.user?.lastname}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Date of Birth: </Typography>
                        <Typography source={'body2'}>
                          {new Date(state?.user?.dob).toLocaleDateString()}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Subscribed: </Typography>
                        <Typography source={'body2'}>{subbed}</Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          source={'h2'}
                          style={{
                            marginTop: 20,
                          }}
                        >
                          Location
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Primary Location:</Typography>
                        <Typography source={'body2'}>
                          {state?.user?.addressLineOne}{' '}
                          {state?.user?.addressLineTwo} {state?.user?.city}{' '}
                          {state?.user?.postcode}
                        </Typography>
                      </div>

                      <Typography
                        source={'h2'}
                        style={{
                          marginTop: 20,
                        }}
                      >
                        Contact Details
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}> Email:</Typography>
                        <Typography source={'body2'}>
                          {state?.user?.email}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}> Home Phone:</Typography>
                        <Typography source={'body'}>
                          {state?.user?.homeNumber}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Mobile:</Typography>
                        <Typography source={'body'}>
                          {state?.user?.mobile}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Bio:</Typography>
                        <Typography source={'body'}>
                          {state?.user?.bio}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography source={'h3'}>Services:</Typography>
                        <Typography source={'body'}>
                          {state?.user?.servicesOffered}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {edit && <Button type="submit">Save</Button>}
                </form>
                <ConfirmationModal
                  onAccept={async () => {
                    await deleteAppUser(state?.user?.id, setAlert)
                    navigate('/dashboard/app')
                  }}
                  isOpen={confirmDelete}
                  onRequestClose={() => setConfirmDelete(false)}
                  type={'Delete'}
                  text={'Are you sure you wish to delete this user?'}
                  icon={MdDeleteForever}
                />
                <ConfirmationModal
                  onAccept={async () => {
                    await resetAppUser(state?.user?.email, setAlert)
                    setConfirmReset(false)
                  }}
                  isOpen={confirmReset}
                  onRequestClose={() => setConfirmReset(false)}
                  type={'Reset'}
                  text={'Are you sure you wish to reset this users password?'}
                  icon={FaUser}
                />
              </div>

              <div
                style={{ width: 300, display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    margin: 20,
                    borderLeft: '1px solid grey',
                    minHeight: 600,
                  }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography source={'h2'} style={{ marginTop: 30 }}>
                    Audit Trail
                  </Typography>
                  <TableContainer source={'audit'} theme={theme}>
                    <Table source={'audit'} theme={theme}>
                      <TableHead source={'audit'} theme={theme}>
                        {['Time', 'Action']?.map((header, index) => {
                          return (
                            <TableCell
                              source={'audit'}
                              key={uuid()}
                              theme={theme}
                            >
                              <Typography>{header}</Typography>
                            </TableCell>
                          )
                        })}
                      </TableHead>
                      {audit?.map((item) => {
                        return (
                          <TableRow source={'audit'} theme={theme} key={uuid()}>
                            <TableCell source={'audit'} theme={theme}>
                              {new Date(item?.updatedAt).toLocaleString()}
                            </TableCell>
                            <TableCell source={'audit'} theme={theme}>
                              {item?.description}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>

            {edit ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {videos?.map((item) => {
                    return (
                      <VideoButton
                        key={uuid()}
                        item={item}
                        image={item?.thumbnailURL}
                        url={item?.playbackURL}
                        id={item?.id}
                        getVids={getVideos}
                        deleteVideo={deleteVideo}
                      />
                    )
                  })}
                </div>
                {max < 1 ? (
                  <></>
                ) : (
                  <Button
                    style={{ margin: 'auto', marginTop: 20 }}
                    onClick={async () => {
                      const res = await getNotFlaggedVideos(
                        page + 1,
                        limit,
                        sort?.value,
                        state?.user?.id
                      )
                      setPage(page + 1)
                      setMax(res?.data.data.length + 1 - limit)
                      setVideos((prev) => [...prev, ...res?.data.data])
                    }}
                  >
                    Load More
                  </Button>
                )}
              </>
            )}
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default EditAppUser
